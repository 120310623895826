import { Link, navigate, PageProps } from 'gatsby';
import React from 'react';
import { StepsList } from '../components/Steps';
import { ContentfulGuideEntity } from '../models/ContentfulTypes';
import {
  TeliaRow,
  TeliaP,
  TeliaHeading,
  TeliaLink,
  TeliaButton,
} from '@teliads/components/react';
import { Layout, Grid } from '../components/Layout';
import '../styles/divider.scss';

interface Props extends PageProps {
  pageContext: {
    divider: ContentfulGuideEntity;
    pageDividers: ContentfulGuideEntity[];
    nextPageUrl: string;
    contentfulId: string;
    deviceSlug: string;
    language: string;
  };
}

export default ({ pageContext }: Props) => {
  const {
    divider,
    pageDividers,
    nextPageUrl,
    contentfulId,
    deviceSlug,
    language,
  } = pageContext;
  const description = divider?.pageDescription?.pageDescription.replace(
    '#',
    pageDividers.length.toString()
  );

  return (
    <Layout
      variant="secondary"
      contentfulId={contentfulId}
      deviceSlug={deviceSlug}
      title={divider.pageTitle}
    >
      {!divider.onlyShowFooter && (
        <Grid variant="secondary" className="grid--full-width">
          <StepsList
            language={language}
            title={divider.pageTitle}
            dividerContentfulId={divider.contentful_id}
            description={description}
            steps={pageDividers}
            navigateTo={() => navigate(nextPageUrl)}
          />
        </Grid>
      )}
      <Grid>
        <TeliaRow className="column gap center padding--vertical divider--message">
          {divider?.pageFooterSubheading && (
            <TeliaHeading tag="h3" variant="display-100">
              {divider?.pageFooterSubheading}
            </TeliaHeading>
          )}
          <TeliaHeading tag="h3" variant="display-100">
            {divider?.pageFooterTitle}
          </TeliaHeading>
          <TeliaP>
            {divider?.pageFooterDescription?.pageFooterDescription}
          </TeliaP>
          <div>
            <img src={divider?.pageFooterImage?.file?.url} alt="" />
          </div>
        </TeliaRow>
        {divider.onlyShowFooter && (
          <TeliaRow className="center padding--vertical gap--sm">
            <Link to={nextPageUrl}>
              <TeliaButton
                variant="primary"
                text={divider.onlyShowFooterButtonText}
              />
            </Link>
          </TeliaRow>
        )}
      </Grid>
    </Layout>
  );
};
